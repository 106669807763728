<template>
  <section>
    <section class="search-container">
      <a-form
        :form="fileForm"
        layout="inline"
        @submit="submitForm"
        class="m-t-14"
      >
        <a-form-item label="材料名称">
          <a-input
            autocomplete="off"
            v-decorator="['materialName']"
            placeholder="请输入"
          />
        </a-form-item>
        <a-form-item label="品牌">
          <a-input
            autocomplete="off"
            v-decorator="['brand']"
            placeholder="请输入"
          />
        </a-form-item>
        <a-form-item label="型号">
          <a-input
            autocomplete="off"
            v-decorator="['type']"
            placeholder="请输入"
          />
        </a-form-item>
        <a-form-item label="仓库">
          <a-select
            placeholder="请选择"
            style="width:140px"
            @change="handleChange"
            v-decorator="['warehouseId']"
          >
            <a-select-option
              v-for="item in warehouseData"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item>
          <a-button type="primary" class="m-r-8" html-type="submit">查询</a-button>
          <a-button @click="reset">重置</a-button>
        </a-form-item>
      </a-form>

      <a-row type="flex" justify="end">
        <a-button @click="exprotFile('materialStockExport',{params:params})" class="item"><a-icon type="export" />导出</a-button>
      </a-row>
    </section>

    <section class="list-table-container list-table">
      <a-table
        :bordered="false"
        :data-source="dataSource"
        size="small"
        class="m-t-18"
        :columns="columns"
        @change="changePageNum"
        :pagination="{ showQuickJumper: false, total:total,current:current }"
      >
        <template slot="operation" slot-scope="text, list">
          <router-link :to="'/mms/inout/stock/stockData?id=' + list.id">
            <a style="color:#529aff">查看</a>
          </router-link>

          <!-- <a-divider style="color:#222" type="vertical" />
          <a style="color:#529aff">删除</a> -->
        </template>
      </a-table>
    </section>
  </section>
</template>
<script>
import {exprotFile} from '@/until/unit'
const columns = [
  { title: "序号", dataIndex: "ind", align: "center" },
  { title: "材料名称", dataIndex: "materialName", align: "center" },
  { title: "品牌", dataIndex: "brand", align: "center" },
  { title: "规则型号", dataIndex: "type", align: "center" },
  { title: "计量单位", dataIndex: "unit", align: "center" },
  { title: "当前库存", dataIndex: "stockQty", align: "center" },
  { title: "存放仓库", dataIndex: "warehouseName", align: "center" },
  {
    title: "操作",
    dataIndex: "operation",
    align: "center",
    scopedSlots: { customRender: "operation" }
  }
];

export default {
  data() {
    return {
      params:{},
      columns,
      materialName: "", //材料名称
      brand: "", //品牌
      warehouseId: "", //仓库ID
      type: "", //型号
      current: 1, //当前页码
      dataSource: [],
      total: 0,
      fileForm: this.$form.createForm(this),
      warehouseData: [] //仓库下拉列表
    };
  },
  created() {
    this.getStockList();
    this.warehouse(); //仓库下拉别表
  },
  methods: {
    exprotFile,
    // 查询
    submitForm(e) {
      e && e.preventDefault();
      let formVal = this.fileForm.getFieldsValue();
      this.materialName = formVal.materialName;
      this.brand = formVal.brand;
      this.type = formVal.type;
      this.warehouseId = formVal.warehouseId;
      this.current=1;
      this.getStockList();
    },
    // 获取仓库id
    handleChange(val) {
      this.warehouseId = val;
    },
    // 分页
    changePageNum(p) {
      this.current = p.current;
      this.getStockList();
    },
    onChange() {},
    // 重置
    reset() {
      this.materialName = "";
      this.brand = "";
      this.type = "";
      this.warehouseId = "";
      this.current = 1;
      // 重置
      this.fileForm.resetFields();
      this.getStockList();
    },
    // 仓库下拉列表
    warehouse() {
      this.$api.warehouseData().then(res => {
        if (res.code == "200") {
          this.warehouseData = res.data ? res.data : [];
        }
      });
    },
    save() {},
    close() {},
    onClose() {
      this.deviceVisible = false;
    },
    //入库单编码
    getStockList() {
      this.params = {
        materialName: this.materialName,
        brand: this.brand,
        warehouseId: this.warehouseId,
        type: this.type,
        current: this.current
      };
      this.$api.getStockList(this.params).then(res => {
      
        if (res.code == "200") {
          this.total = res.data.total;
          if (res.data.records && res.data.records.length > 0) {
            res.data.records.forEach((item, index) => {
              item.ind = index + 1;
              item.key = index + 1;
            });
          }
          this.dataSource = res.data.records;
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.ant-calendar-picker-input {
  width: 100%;
}
/deep/ .ant-calendar-picker-icon {
  color: #93bbf3 !important;
}
.explain {
  margin-top: 30px;
  border-top: 1px dashed;
}
</style>